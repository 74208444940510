import Card from "../home/components/card";
import Pricing from "../location/components/Pricing";
import Schedule from "../location/components/Schedule";

export default function Facility() {
    const cardsData = [
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.27%402x.webp?alt=media&token=421b22eb-20fb-4efe-b694-04d5d4dad06d", adjustment:"bg-cover", title: "VR Room", subtitle: "Experience the future", facility: "vr-room" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.12.03%402x.webp?alt=media&token=62d967aa-3d89-4647-ba76-e9a1d34cf48d", adjustment:"bg-cover", title: "Creative Arts", subtitle: "Express yourself", facility: "creative-arts" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.10.42%402x.webp?alt=media&token=b94739c4-d4f7-407c-b359-5826369029d6", adjustment:"bg-cover bg-center bg-top", title: "Digital Creativity", subtitle: "Create media", facility: "digital-creativity" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.15.42%402x.webp?alt=media&token=fd158da2-dc9c-4e30-8a36-f63c2fd67290", adjustment:"bg-cover", title: "Adulting 101", subtitle: "Life skills", facility: "adulting-101" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.38%402x.webp?alt=media&token=19152ca0-f9f5-4ace-869c-7b50ce722150", adjustment:"bg-cover bg-bottom", title: "Socialisation", subtitle: "Connect positively", facility: "socialisation" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.10.54%402x.webp?alt=media&token=10fcf508-c13a-4ecf-be1c-6724b3f0515f", adjustment:"bg-cover", title: "Activities", subtitle: "Engage & compete", facility: "activities" },
        { img: "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.11.10%402x.webp?alt=media&token=28675e57-1fe4-4aa2-a83e-fa1d473a1fd8", adjustment:"bg-cover", title: "Trips & Summer Camps", subtitle: "Travel The World", facility: "trips-summer-camps" },
      ];
      const locations = {
        "hermanus": {
          title: "Hermanus",
          content: "Located along the beautiful coast of South Africa, our Hermanus hub offers a serene environment conducive to learning and personal growth. Students can enjoy the natural beauty of the area while participating in various social and educational activities designed to enhance their overall experience.",
          activities: [
            { time: '10h00 - 11h30', Monday: 'Virtual Reality', Tuesday: 'Free Art', Wednesday: 'Robotics and Coding', Thursday: 'Free Art', Friday: 'Digital Creativity' },
            { time: '12h00 - 13h30', Monday: 'Free Art', Tuesday: 'Learning Support', Wednesday: 'Mid-week Hike/Trip', Thursday: 'Social Builder', Friday: 'Learning Support' },
            { time: '14h00 - 15h30', Monday: 'Digital Creativity', Tuesday: 'Virtual Reality', Wednesday: '', Thursday: 'Virtual Reality', Friday: 'Career Explorer' },
            { time: '16h00 - 17h30', Monday: 'Social Builder', Tuesday: 'Adulting 101', Wednesday: '', Thursday: 'Robotics and Coding', Friday: '' },
            { time: '18h00 - 19h30', Monday: 'Adulting 101', Tuesday: 'Digital Creativity', Wednesday: '', Thursday: 'Career Explorer', Friday: '' },
          ],
          images: [
            "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2F01g.webp?alt=media&token=c6e4bdec-d8ae-4557-a9cd-735a7b559fbb",
            "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2F4389231-birkenhead-house-hotel-hermanus-south-africa.webp?alt=media&token=e6a54642-c662-4bc0-8d81-7914cc3cae1c",
            "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FHermanus_Old_Harbour.webp?alt=media&token=3076c212-c54d-4a83-ae43-1478c919829e",
            "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FIMG_3300-1170x550.webp?alt=media&token=210e4eb9-150d-4a85-9387-f2bf5076a740",
            "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FHermanus%2FUS_Hermanus_ZA_Header.webp?alt=media&token=b13c6553-7940-4473-bca3-257be1c8dbb5"
          ]
        },
    }      
    return (
        <section className="mt-20">
            {/* <Schedule activities={locations.hermanus.activities} /> */}
{/*             <Pricing location={"hermanus"}/>
 */}             <h2 className='text-center'>Facilities</h2>
            <div className="flex flex-wrap gap-2 justify-center max-w-5xl mx-auto py-2 px-10">
                {cardsData.map((card, index) => (
                    <Card adjustment={card.adjustment} key={index} img={card.img} title={card.title} subtitle={card.subtitle} facility={card.facility ?? 'not-found'} />
                ))} 
            </div>
        </section>
    )
}
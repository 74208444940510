import React, { useRef } from 'react';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';

const ScrollComponent = (props) => {
  const ref = useRef(null);

  // Using useScroll to link the scroll progress to the ref of the component
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start 0.5', 'end 0.5'], // Calculate progress from top to bottom of the component
  });

  // Compute the opacity based on the scroll progress. The circle is only visible when the scrollYProgress is 1 (fully visible bar)
  const opacity = useTransform(scrollYProgress, [0, 0.03], [0, 1]);

  return (
    <div ref={ref} >

          <motion.div className='bg-gray-200 w-4 h-4 rounded-full p-4 lg:mx-auto -my-4 ml-1/2 relative z-20'
                style={{ opacity }}
              >
        </motion.div>

      <div className={`grid grid-cols-7 h-full relative ${props.last ? "lg:pb-32" : ""}`}>
        <motion.div 
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5 }}
          className={`col-span-6 lg:col-span-3 lg:col-start-1 col-start-2 flex justify-end bg-gray-200 ml-auto rounded-2xl relative items-end max-w-md lg:-bottom-1/2 my-10 ${props.firstTitle ? '' : 'hidden'}`}>
          <div className='prose m-5 text-ll prose-h3:bg-b prose-h3:bg-clip-text prose-h3:text-transparent'>
            <h3>{props.firstTitle}</h3>
            <div dangerouslySetInnerHTML={{__html:props.firstDescription}}>
            </div>
          </div>
        </motion.div>
        <motion.div
          className={`w-4 bg-b right-0 ml-2 lg:mx-auto ease-linear col-start-1 lg:col-start-4 row-start-1 row-span-2 `}
          style={{ scaleY: scrollYProgress, originY: 0 }} // Scale based on modified scroll progress
        >
          
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.5 }}  
          className={`col-span-6 lg:col-span-3 lg:col-start-5 col-start-2 flex justify-center bg-gray-200 mr-auto rounded-2xl relative lg:-bottom-1/2 h-max max-w-md ${props.lastTitle ? ' ': 'hidden'}`}
        > 
        <div className='prose text-ll m-5 prose-h3:bg-b prose-h3:bg-clip-text prose-h3:text-transparent '>
        <h3>{props.lastTitle}</h3>
        <div dangerouslySetInnerHTML={{__html:props.lastDescription}}>

        </div>
      </div>
        </motion.div>
      </div>
    </div>
  );
};

const Scroll = () => {
  return (
    <div className='py-20'>
      <h2 className="text-5xl font-bold text-transparent bg-b bg-clip-text pb-3 mx-auto max-w-6xl pt-8 text-center">Enrolling in iHubs</h2>

      <div className='prose px-7 pt-5 pb-3 text-ll m-5 prose-h3:bg-b prose-h3:bg-clip-text prose-h3:text-transparent bg-gray-200 lg:w-1/3 w-11/12 lg:mx-auto rounded-2xl -mb-1 max-w-sm'>
        <h3 className="">1. Fill in the Application Form</h3>
        <div className='-mt-5'>
            <p>Begin your journey by completing the application form. This step ensures that we have all the necessary information to tailor our services to your needs.</p>
        </div>
      </div>
      <ScrollComponent firstTitle="2. Choose your activities" firstDescription="<p>Choose 3-5 activities that you'd like to do each week. Go as broad as you can to expand your horizons.</p>"  />
      <ScrollComponent lastTitle="3. Visit our Physical Location" lastDescription="<p>Engage in regular visits to our iHubs centres. Here, students participate in social development activities, hands-on learning experiences, and benefit from our facilities that complement their online education.</p>" />
      <ScrollComponent firstTitle="4. No Negative Socialisation" firstDescription="<p>Enjoy a safe and positive social environment at iHubs. We ensure that students are shielded from negative influences such as bullying and inappropriate behaviour, promoting healthy and supportive interactions.</p>" />
      <ScrollComponent last lastTitle="5. Develop into a confident Adult" lastDescription="<p>Throughout their time at iHubs, students gain essential life skills through our 'Adulting 101' classes and various other programmes, preparing them for independent living and future challenges.</p>" />
      <div className='prose px-7 pt-5 pb-3 text-ll m-5 prose-h3:bg-b prose-h3:bg-clip-text prose-h3:text-transparent bg-gray-200 lg:w-1/3 w-11/12 lg:mx-auto rounded-2xl -mb-1 max-w-sm'>
          <h3 className="">6. Understood & Prepared</h3>
          <div className='-mt-5'>
              <p>By the end of their journey with iHubs, students will have a thorough understanding of their academic content, be well-prepared for exams, and equipped with the skills and confidence needed to thrive in the real world.</p>
          </div>
      </div>
    </div>
  );
};

export default Scroll;

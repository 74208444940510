export default function Hero() {
    return (
        <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
            <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
            />
            <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto lg:mx-0 space-y-4 ">
                <div>
                    <h1 className="text-2xl sm:text-3xl font-bold tracking-tight text-gray-900">About Us</h1>
                    <h2 className="max-w-5xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                    We’re a passionate group of people working from around the world to build the future of education.
                    </h2>
                </div>
                <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                At iHubs, we bridge the gap between the flexibility of online education and the vital need for social development. While online learning and homeschooling enable students to focus on academics at their own pace, they often miss out on the crucial social interactions that shape personal growth. That’s where we come in. We are not a school, we facilitate opportunities for social development and personal growth.
                </p>
                <p className="text-lg leading-8 text-gray-600">
                We plan to have a global network of centres that provide a safe, secure, and enriching environment where students can connect, collaborate, and thrive. We understand the challenges of traditional schooling, from negative social influences like bullying to unhealthy behaviours that can hinder growth. At iHubs, we prioritise fostering positive relationships and creating a supportive community, ensuring every learner has the opportunity to develop both academically and socially in the best possible way.
                </p>
                </div>
            </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>

    )
}
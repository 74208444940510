export default function Hero() {
    return (
        <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
            <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
            />
            <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto lg:mx-0 space-y-4">
                <h1 className="max-w-4xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                iHubs provides the solution for online and homeschool isolation
                </h1>
                <div className="mt-6 max-w-3xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                While online education and homeschooling offer unparalleled flexibility and allow learners to focus on their studies, they often lack the social interaction crucial for personal development. iHubs provides the perfect solution to this challenge by opening centres all over the world - starting in South Africa, where students can engage in their social development in a safe and secure environment.
                Traditional schooling can sometimes expose students to negative socialisation, such as bullying, learning inappropriate behaviour. iHubs ensures a positive and supportive social environment, promoting healthy interactions and personal growth.
                </p>
                </div>
            </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>

    )
}
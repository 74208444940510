import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

const facilityDetails = {
  "vr-room": {
    title: "VR Room",
    content: "At iHubs, we have integrated virtual reality into our curriculum to provide students with an immersive learning experience. With our state-of-the-art VR headsets, students can engage with the technology of tomorrow while linking it to their current studies. This hands-on approach allows students to explore new dimensions of learning and stay ahead in the technological landscape.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FVR%20Room%2F1-modera-belmont-vr-lounge-credit-christian-columbres.webp?alt=media&token=6b26cac5-5711-4bc9-afbf-96a2ac255272",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FVR%20Room%2FEscapeVRVirtualRealityArcade.webp?alt=media&token=925d57d3-a733-49ae-88c1-5e28c699fe57",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FVR%20Room%2FVR-studio-empty.webp?alt=media&token=e5f0bc18-4a8e-4fc1-b1df-88de9377154c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FVR%20Room%2Fbuild-the-best-vr-gaming-setup3-6d20ff18-921d-437d-9d15-9731b97998bc.webp?alt=media&token=24e0f48d-bab6-484f-9379-b80836bdc1a0",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FVR%20Room%2Fec930fe87c4c1391de4946351d0967c2.webp?alt=media&token=40d07848-4ee9-4fb8-9960-4326b982a7ff"
    ]
  },
  "creative-arts": {
    title: "Creative Arts",
    content: "Our Creative Arts area allows students to explore traditional art forms. They can enjoy activities such as free painting, pencil drawing, sketching, and other creative projects throughout the year, fostering their artistic talents and creativity.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FCreative%20Arts%2FArt_Classes_Fall_2024-scaled.webp?alt=media&token=d437820b-41ed-42b1-a482-acc4d6d6eef6",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FCreative%20Arts%2Fart_class_credit_anon-tae.webp?alt=media&token=b49cbb04-45b2-49c0-a3ce-e7343afc3f95",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FCreative%20Arts%2Fartist-s-hand-and-sketch--84749038-5af495c31d64040036c96242.webp?alt=media&token=d6cb8e0d-4b65-420d-a8db-3430ebbe0e0c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FCreative%20Arts%2Fimages.webp?alt=media&token=bb31fbe4-ad5d-4e00-be76-64cbfcef3b11",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FCreative%20Arts%2Fistockphoto-1409888072-612x612.webp?alt=media&token=812c44aa-2a17-4532-a5b3-cef38e09179a"
    ]
  },
  "digital-creativity": {
    title: "Digital Creativity",
    content: "With our green screen, high-quality recording equipment, and editing software, students can delve into digital creativity. They have the opportunity to create various types of media, including animated video sequences, promotional material, and short films.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FDigital%20Creativity%2FSSS-_1.1.1.webp?alt=media&token=23a0a2ed-78f7-490f-868f-dcb53c11a467",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FDigital%20Creativity%2Fclipper-gae55396b0_1920.webp?alt=media&token=a46b8b63-51d4-4f60-b15e-170bf29a3392",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FDigital%20Creativity%2FmZWTSqsHwPqLJ4WCez9mSm.webp?alt=media&token=ec598292-7e7c-4a53-9a04-6849bd493e14",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FDigital%20Creativity%2Fpexels-pixabay-257904.webp?alt=media&token=19b83fd2-b3be-4608-9675-7a3492c588a9",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FDigital%20Creativity%2Fphotoshop-for-photographers.webp?alt=media&token=02e6f345-6b36-41a0-83ef-e6e85fca0788",

    ]
  },
  "adulting-101": {
    title: "Adulting 101",
    content: "Our bespoke courses are specifically designed to equip students with essential life skills needed for adulthood. These interactive sessions cover a range of topics from budgeting and basic cooking to DIY skills, preparing students for real-world challenges.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAdulting%20101%2Fhero-image.fill.size_1248x702.v1699834393.webp?alt=media&token=187a04f5-3cb9-4d92-bca7-2177f79b080d",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAdulting%20101%2Fhow-to-hang-a-picture-5195067-15-c8e00a4053d64349a252b8edea7ff420.webp?alt=media&token=a64582c8-3dd4-4e2f-be9e-ed593f5dfb54",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAdulting%20101%2Fhq720.webp?alt=media&token=1ce8b14a-a832-4428-b26d-080bd4c0e72c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAdulting%20101%2Fpexels-cottonbro-4482012.webp?alt=media&token=22948b36-4709-49dc-b194-9c7022474ad4",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FAdulting%20101%2Fwearlovewanders-11.webp?alt=media&token=7103d396-1cff-4005-9128-e77e375052c8",
    ]
  },
  "socialisation": {
    title: "Socialisation",
    content: "iHubs provides a safe and secure environment for positive socialisation. Unlike traditional schools, which may expose students to negative socialisation, our platform ensures that students engage in healthy and supportive social interactions.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2FGettyImages-976595402.webp?alt=media&token=3a2b927d-36e2-4a7f-874c-92a09b6c7b2c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fautism-spectrum-disorder-conversation-teenagersnarrow.webp?alt=media&token=d544a7fc-034e-4440-9c48-abca11e91a10",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fsocial_skills_for_teens_1920x.webp?alt=media&token=af94366a-199e-4b06-a94e-e3d3a9472dbd",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fteenage-friendships.webp?alt=media&token=7e789fd3-12ee-4f65-b877-e074aae0c362",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fteens-socializing.webp?alt=media&token=698c6bea-402b-4438-b4d4-823e3f68a156",
    ]
  },
  "activities": {
    title: "Activities",
    content: "To promote holistic development, iHubs offers various activities throughout the academic year. These include weekly events like hiking and educational trips, as well as annual events such as sports day, English week, and creative arts evenings, fostering healthy competition and social skills.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2FGettyImages-976595402.webp?alt=media&token=3a2b927d-36e2-4a7f-874c-92a09b6c7b2c",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fautism-spectrum-disorder-conversation-teenagersnarrow.webp?alt=media&token=d544a7fc-034e-4440-9c48-abca11e91a10",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fsocial_skills_for_teens_1920x.webp?alt=media&token=af94366a-199e-4b06-a94e-e3d3a9472dbd",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fteenage-friendships.webp?alt=media&token=7e789fd3-12ee-4f65-b877-e074aae0c362",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FSocialisation%20Activities%2Fteens-socializing.webp?alt=media&token=698c6bea-402b-4438-b4d4-823e3f68a156",
    ]
  },
  "trips-summer-camps": {
    title: "Trips & Summer Camps",
    content: "iHubs also serves as an associate exam centre for Cambridge and Pearson examinations. This provides an added benefit for students, allowing them to take these examinations in a familiar and supportive environment, with fees charged at cost.",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FTrips%20%26%20Summer%20Camps%2FBCCYMCA_Waterfront.webp?alt=media&token=386a8bd9-fc06-483d-babc-7ff662df0f6e",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FTrips%20%26%20Summer%20Camps%2Fcamp-fimfo-tubing-SUMMERCAMP0622-aaefe3275cd94636aa24956b2564a801.webp?alt=media&token=55ede02b-2383-42e0-9b19-cf2a602656f6",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FTrips%20%26%20Summer%20Camps%2Ffieldtrips.webp?alt=media&token=4398b0a8-3fea-4ae2-9cbf-80a20920ba37",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FTrips%20%26%20Summer%20Camps%2Fmartial-arts-camping-trip-1037x534.webp?alt=media&token=89192c73-de18-4386-9f2d-e26212559eed",
      "https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Bulk%20Images%2FTrips%20%26%20Summer%20Camps%2Fsummer-camps-rafting.webp?alt=media&token=13400a13-927f-42ee-8a49-bbb4046d023f",
    ]
  }
};

function FacilityDetail() {
  const { facility } = useParams();
  console.log(facility)
  const facilityDetail = facilityDetails[facility];

  if (!facilityDetail) {
    return <p className='m-auto mt-26'>Facility not found.</p>;
  }
  const pages = [
    { name: "Facilities", href: '/facilities', current: false },
    { name: facility, href: '#', current: true },
  ]

  return (
    <section className='mt-24 text-center relative'>
      <div className='px-20'>
        <nav className="flex justify-center" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <Link
                    to={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <h2 className='text-center'>{facilityDetail.title}</h2>
      <p className='max-w-prose mx-auto'>{facilityDetail.content}</p>
      <div className='h-screen md:h-[60vh]'>
        <div className='grid-cols-2 grid-rows-4 md:grid-cols-4 md:grid-rows-2 grid gap-4 w-4/5 h-3/5 md:h-full m-auto my-20'>
            <img className='row-start-2 md:row-start-1 col-span-1 row-span-2 object-cover rounded-xl overflow-hidden w-full h-full ' alt="location" src={facilityDetail.images[0]}></img>
            <img className='row-start-4 md:row-start-1 col-span-2 row-span-1 object-cover rounded-xl overflow-hidden w-full h-full ' alt="location" src={facilityDetail.images[1]}></img>
            <img className='row-start-3 md:row-start-1 col-span-1 row-span-1 object-cover rounded-xl overflow-hidden w-full h-full ' alt="location" src={facilityDetail.images[2]}></img>
            <img className='row-start-2 col-span-1 row-span-1 object-cover rounded-xl overflow-hidden w-full h-full ' alt="location" src={facilityDetail.images[3]}></img>
            <img className='col-span-2 row-span-1 object-cover rounded-xl overflow-hidden w-full h-full ' alt="location" src={facilityDetail.images[4]}></img>
        </div>
      </div>
    </section>
  );
}

export default FacilityDetail;

import Button from "../../../components/Button";
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { ChevronDown } from "react-feather";

const AccordianContext = createContext()

function Accordian({ children, value, onChange, ...props }) {
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        onChange?.(selected)
    }, [selected])

    return (
        <ul {...props}>
            <AccordianContext.Provider value={{ selected, setSelected }}>
                {children}
            </AccordianContext.Provider>
        </ul>
    )
}


function AccordianItem({ children, value, index, trigger,selectedData, ...props }) {
    const { selected, setSelected } = useContext(AccordianContext);

    const open = selected === value;
    const ref = useRef(null)    

    return (
        <li className="bg-white" {...props}>
            <div 
            className="flex justify-between items-center py-2.5"
            role="button"
            onClick={() => setSelected(open ? null : value)}
            >
                <div className="flex items-center space-x-3">
                    <p className="text-xl md:text-2xl font-light text-start">{trigger}</p>
                </div>
                <ChevronDown size={16} className={`transition-transform ${open ? "rotate-180" : ""}`} />
            </div>
            <div className="overflow-y-hidden transition-all duration-300 ease-in-out"
                style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
            >
                <div className="pt-2 p-4" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
    )
}

const data = [
    {
    title: "How does iHubs enhance social interaction for online students",
    text: "iHubs provides centres located worldwide where students can engage in social development activities in a safe and secure environment. These centres offer various opportunities for students to interact, collaborate, and build social skills through group activities, events, and workshops."
    },
    {
    title: "How does iHubs prepare students for the future?",
    text: "iHubs prepares students for the future by offering innovative modules on future technologies, mindset development, providing life skills education through 'Adulting 101' classes, and fostering creativity through our digital and art facilities. Our holistic approach ensures students are well-rounded and ready for the challenges of the 21st century."
},
    {
    title: "What is the difference between iHubs and traditional schools?",
    text: "Unlike traditional schools, iHubs combines the flexibility of online learning with the social benefits of in-person interaction. Our hybrid model provides a balanced approach to education, ensuring students excel academically while developing crucial social and career skills."
},
];

export default function About() {
    return (
        <section className="text-center lg:p-10 w-full">
            <div className="flex flex-col lg:flex-row justify-center">
                <div className="px-2 lg:text-start lg:w-1/2">
                    <h2 className="">What is iHubs</h2>
                    <p className="prose">
                        iHubs is an innovative model that bridges the gap between online/home-schooling and socialisation. iHubs offers weekly activities to support and promote social skills development and annual events, such as sports day, literature day, science day, etc.
                    </p>
                    <p className="pb-5 prose">
                        As iHubs opens new centres around the world, international competitions and events will be scheduled. With our various international partners iHubs members have the opportunity to parttake in international school competitions.
                    </p>
                    <Button text="Apply" className="mx-auto lg:mx-0" />
                </div>
                 <div className="lg:w-1/2 p-10">
                    <Accordian className="space-y-3">
                        {data.map((item, index) => (
                            <AccordianItem trigger={item.title} selectedData={data} index={index+1} className="border-t border-b-0 border-b pt-4 text-start" value={`${index+1}`}>
                                {item.text}
                            </AccordianItem>
                        ))}
                    </Accordian>
                </div>
             </div>
            <div className="px-0">
        {/*         <div className="flex flex-col md:flex-row gap-10 justify-center py-20">
                    <img className="rounded-xl h-[500px] md:h-auto md:w-2/5 object-cover" src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/Facilities%2FCleanShot%202024-09-30%20at%2012.26.04%402x.webp?alt=media&token=f587ae6f-9917-4c2c-a3de-d31a7ec212f5" alt=""></img>
                    <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto">
                        <h3>Online Schooling at Inventum through iHubs</h3>
                        <ul className="">
                            <li>Prestigious Cambridge Qualification: Earn a globally respected Cambridge qualification recognised by top universities and employers.</li>
                            <li>Future Technology and Mindset Modules: Study innovative subjects on future technologies and growth mindsets, preparing you for modern challenges.</li>
                            <li>Flexible Schedule: Learn at your own pace with adaptable online schedules that fit your lifestyle.</li>
                            <li>Cambridge Advantage: Benefit from a rigorous and comprehensive Cambridge curriculum, superior to IEB and GAPS.</li>
                            <li>Study Abroad at Ease: A-Levels facilitate smoother admissions to international universities.</li>
                            <li>Individualised Support: Receive personalised attention tailored to your unique learning needs.</li>
                        </ul>
                        <Button />
                    </div>
                </div> */}
                <div className="flex flex-col md:flex-row-reverse gap-10 justify-center py-20">
                    <img className="rounded-xl h-[500px] md:h-auto md:w-2/5 object-cover" src="https://firebasestorage.googleapis.com/v0/b/ihubss.appspot.com/o/oluwatobi-fasipe-gu9X51yzei0-unsplash.webp?alt=media&token=73f37e43-8119-4e9d-9d79-876c7498de64" alt=""></img>
                    <div className="prose prose-h3:text-2xl md:prose-h3:text-4xl prose-h3:font-bold text-start md:w-1/2 my-auto">
                        <h3>The benefits of iHubs</h3>
                        <ul className="">
                            <li>Socialisation: iHubs provides socialisation for online/homeschool students and we mitigate the negative aspects of socialisation that traditional brick-and-mortar schools have.</li>
                            <li>Innovative Facilities: Access VR and AR rooms, digital recording studios, and art lessons to enhance creativity and make learning engaging and fun.</li>
                            <li>Life Skills Education: "Adulting 101" classes teach essential life skills, preparing students for independent living and future challenges.</li>
                            <li>Diverse Activities: Participate in exciting trips, sports days, hiking, and social events that promote teamwork, leadership, and social skills.</li>
                        </ul>
                        <Button />
                    </div>
                </div>
            </div>
        </section>
    )
}
import Hero from "./components/Hero";
import Stats from "./components/Stats";
import Team from "./components/Team";

export default function About() {
    return (
        <div>
            <Hero />
            <Team />
        </div>
    )
}
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { ChevronDown } from "react-feather";

const AccordianContext = createContext()

function Accordian({ children, value, onChange, ...props }) {
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        onChange?.(selected)
    }, [selected])

    return (
        <ul {...props}>
            <AccordianContext.Provider value={{ selected, setSelected }}>
                {children}
            </AccordianContext.Provider>
        </ul>
    )
}


function AccordianItem({ children, value, index, trigger,selectedData, ...props }) {
    const { selected, setSelected } = useContext(AccordianContext);

    const open = selected === value;
    const ref = useRef(null)    

    return (
        <li className="bg-white" {...props}>
            <div 
            className="flex justify-between items-center py-2.5"
            role="button"
            onClick={() => setSelected(open ? null : value)}
            >
                <div className="flex items-center space-x-3">
                    <p className="text-xl md:text-2xl font-light">{trigger}</p>
                </div>
                <ChevronDown size={16} className={`transition-transform ${open ? "rotate-180" : ""}`} />
            </div>
            <div className="overflow-y-hidden transition-all duration-300 ease-in-out"
                style={{ height: open ? ref.current?.offsetHeight || 0 : 0 }}
            >
                <div className="pt-2 p-4" ref={ref}>
                    {children}
                </div>
            </div>
        </li>
    )
}
const faq = [
    {
        title: "How does iHubs enhance social interaction for online students?",
        text: "iHubs provides centres located worldwide where students can engage in social development activities in a safe and secure environment. These centres offer various opportunities for students to interact, collaborate, and build social skills through group activities, events, and workshops."
    },
    {
        title: "What facilities are available at iHubs?",
        text: "iHubs boasts facilities including VR and AR rooms, digital recording studios, art studios, and dedicated spaces for social activities. These facilities support a range of educational and creative activities designed to enhance the learning experience."
    },
    {
        title: "What kind of activities does iHubs offer?",
        text: "iHubs offers a diverse range of activities such as field trips, sports days, hiking trips, summer camps, and social events. Additionally, we provide 'Adulting 101' classes to teach essential life skills, preparing students for independent living and future challenges."
    },
    {
        title: "How does iHubs support academic success?",
        text: "We offer bespoke support, coaching and advisory services."
    },
    {
        title: "Who can enrol in iHubs?",
        text: "iHubs is open to all online/home-schooled students. Our programme is designed to complement online/home-schooling by providing additional opportunities for social and personal development."
    },
    {
        title: "How does iHubs prepare students for the future?",
        text: "iHubs prepares students for the future by offering innovative modules on future technologies, mindset development, providing life skills education through 'Adulting 101' classes, and fostering creativity through our digital and art facilities. Our holistic approach ensures students are well-rounded and ready for the challenges of the 21st century."
    },
    {
        title: "Where are iHubs centres located?",
        text: "iHubs centres are located in various countries around the world. This global presence allows students to benefit from social interaction and development opportunities regardless of their geographic location."
    },
    {
        title: "What is the difference between iHubs and traditional schools?",
        text: "Unlike traditional schools, iHubs combines the flexibility of online learning with the social benefits of in-person interaction. Our hybrid model provides a balanced approach to education, ensuring students excel academically while developing crucial social and career skills."
    },
    {
        title: "How can I get more information about iHubs?",
        text: "For more information about iHubs in your location, please click on the waving hand (bottom right corner) and get in touch with us!." 
    }
];




export default function FAQ() {

    return (
        <div className="max-w-5xl mx-auto py-20 mt-16">
            <div className="px-10">
                <h2 className="text-2xl md:text-4xl">Frequently Asked Questions.</h2>
                <div className='flex flex-col justify-center py-20'>
                    <Accordian className="space-y-3">
                        {faq.map((item, index) => (
                            <AccordianItem trigger={item.title} index={index+1} className="border-b pb-4" value={`${index+1}`}>
                                {item.text}
                            </AccordianItem>
                        ))}
                    </Accordian>
                </div>
            </div>
        </div>
    )
}